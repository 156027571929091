body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Kanit', 'Roboto';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-header {
  background: rgb(154, 217, 219);
  background: radial-gradient(circle, rgb(41, 41, 41) 0%, rgb(0, 0, 0) 100%);
}

.input-time {
  background-color: rgba(96, 108, 110, 0.15);
  /* height: 40px; */
  padding: 10px 10px;
  color: #fff;
  font-size: 16em;
  outline: 0 solid transparent;
  border: 0 solid transparent;
  /* width: 100%; */
  border-radius: 4px;
  letter-spacing: -0.4px;
  padding: 10px 18px;
  width: 90%;
  text-align: center;
}

.input-time-countdown {
  background-color: rgba(28, 56, 61, 0);
}

.description-text {
  margin-top: 1px;
  margin-bottom: 2px;
  font-weight: 100;
  color: #e0e0e0;
}

.display-none{
  display: none !important;
}